import { graphql } from 'gatsby';
import { object } from 'prop-types';
import { StaticPagesTemplate } from '../../components/common/static-pages-template';

function CentennialChildCustodyAttorney({ data }) {
  return (
    <StaticPagesTemplate data={data} />
  );
}

export const query = graphql`
  {
    wpPage(uri: {eq: "/centennial-child-custody-attorney/"}) {
      content
      title
      uri
      ...SeoPage
    }
  }
`;

CentennialChildCustodyAttorney.propTypes = {
  data: object,
};

CentennialChildCustodyAttorney.defaultProps = {
  data: {},
};

export default CentennialChildCustodyAttorney;
export { Head } from '../../components/seo/seo';
